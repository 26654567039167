import React from 'react';
import styles from '../components/styles/access.module.css';
import buttonStyles from '../components/styles/button.module.css';

export const AccessMap = () => {
  const ADDRESSES = ['〒160-0022', '東京都新宿区新宿5丁目2番3号　MRCビル7F'];

  const DIRECTIONS = [
    '各線　新宿三丁目駅より徒歩8分',
    '東京メトロ丸の内線　新宿御苑前駅より徒歩5分',
  ];

  const GOOGLE_MAP_SRC =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.4033741197136!2d139.70935281511163!3d35.69169008019192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d9d6a765849%3A0x5601f10aa9fafddd!2z44OQ44O844Kv44Oq44O844O744K444Oj44OR44Oz5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1597811639998!5m2!1sja!2sjp';

  return (
    <>
      <div className={styles.address}>
        {ADDRESSES.map((address, i) => (
          <p key={`address-${i}`}>{address}</p>
        ))}
        <div className={styles.directions}>
          {DIRECTIONS.map((direction, i) => (
            <p key={`access-${i}`}>{direction}</p>
          ))}
        </div>
        <div className={styles.btn}>
          <a
            href='https://g.page/Varcly?share'
            target='_blank'
            rel='noopener noreferrer'
            className={`${buttonStyles.button} ${styles.button}`}
            text-value='GoogleMap ▶︎'
          >
            GoogleMap ▶︎
          </a>
        </div>
      </div>
      <iframe
        title='googlemap'
        src={GOOGLE_MAP_SRC}
        className={styles.googleMap}
        allowFullScreen=''
        aria-hidden='false'
      ></iframe>
    </>
  );
};
